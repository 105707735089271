<template>
  <div class="review-settings">
    <GlobalInfoBar
      title="审单报价设置"
      content="可在此处设置和查看审单报价的回收商商家和管理订单的审单异常项及应用的门店商家"
    />
    <div class="merchant-list">
      <div class="title">审单商家列表</div>
      <div class="flex flex-je">
        <el-button size="small" type="primary" @click="openAddMerchantDia"
          >添加回收商</el-button
        >
      </div>
      <div>
        <el-form ref="merchantForm" :model="merchantForm" inline size="small">
          <el-form-item label="回收商" prop="merchantId">
            <el-select
              v-model="merchantForm.merchantId"
              clearable
              filterable
              placeholder="请输入回收商名称查询"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="审单状态" prop="isOpenExamineQuote">
            <el-select
              v-model="merchantForm.isOpenExamineQuote"
              clearable
              placeholder="请选择审单状态查询"
            >
              <el-option label="开启" :value="true" />
              <el-option label="关闭" :value="false" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round :loading="merchantLoading" @click="searchMerchant(1)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <GlobalTable
          ref="GlobalTable"
          v-loading="merchantLoading"
          isIndex
          :columns="merchantColumns"
          :data="merchantDataList"
          :pageSizes="[5,10,20,50]"
          :currentPage="merchantForm.pageNum"
          :pageSize="merchantForm.pageSize"
          :total="merchantForm.total"
          layout="total,sizes, prev, pager, next, jumper"
          @handleCurrentChange="searchMerchant"
          @handleSizeChange="handleMerchantSizeChange"
        >
          <el-table-column
            label="添加人"
            slot="openExamineQuoteAdmin"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.openExamineQuoteAdmin }}-{{ row.openExamineQuoteAdminAcc }}
            </template>
          </el-table-column>
          <el-table-column
            label="添加时间"
            slot="openExamineQuoteTime"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.openExamineQuoteTime || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="商家状态" slot="isEnable" align="center">
            <template slot-scope="{ row }">
              <span :style="!row.isEnable ? 'color:#ff8080;' : ''">{{
                !row.isEnable ? "禁用" : "启用"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单过滤最大时效" slot="examineFilterMaxSeconds" align="center">
            <template slot="header">
              订单过滤最大时效
              <el-tooltip
                class="item"
                effect="dark"
                content="商户自行设置订单过滤时效时不可超出此时效"
                placement="top"
              >
                <i class="el-icon-warning-outline" />
              </el-tooltip>
            </template>
            <template slot-scope="{ row }">
              <div>{{row.examineFilterMaxSeconds}}S</div>
              <span class="txt-underline" @click="examineFilterMaxSecondsSet(row)">设置</span>
            </template>
          </el-table-column>
          <el-table-column label="优先报价顺序" slot="examineNextSort" align="center">
            <template slot-scope="{ row }">
              <div>{{row.examineNextSort =='00' ?'最早加入队列':row.examineNextSort =='01'?'最晚加入队列':'--'}}</div>
              <span class="txt-underline" @click="openExamineNextSortDia(row)">设置</span>
            </template>
          </el-table-column>
          <el-table-column
            label="水印状态"
            slot="isOpenExamineQuoteWatermark"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleSwitch(row)"
                v-model="row.isOpenExamineQuoteWatermark"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="无法开机推单" slot="isExamineUnStart" align="center">
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleStartSwitch(row)"
                v-model="row.isExamineUnStart"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="小程序审单" slot="isEnableWechatExamine" align="center">
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="开启后，商户可以自行选择审单员使用小程序审单"
                placement="top"
              >
                <i class="el-icon-warning-outline" />
              </el-tooltip>
              小程序审单
            </template>
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleXcxSwitch(row)"
                v-model="row.isEnableWechatExamine"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="无法报价" slot="isExamineRejectQuote" align="center">
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleRefuseSwitch(row)"
                v-model="row.isExamineRejectQuote"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="审单状态" slot="isOpenExamineQuote" align="center">
            <template slot-scope="{ row }">
              <!-- <el-button type="danger" size="small" @click="delmerchant(row)"
                >移除</el-button
                > -->
              <el-switch
                inactive-color="#bebebe"
                @change="handleStatusSwitch(row)"
                v-model="row.isOpenExamineQuote"
              ></el-switch>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <div class="merchant-list" style="margin-top: 20px">
      <div class="title">审单示例图</div>
      <div class="flex flex-je">
        <el-button size="small" type="primary" @click="openAddSampleImgDia"
          >添加示例图</el-button
        >
      </div>
      <div>
        <el-form ref="sampleImgForm" :model="sampleImgForm" inline size="small">
          <el-form-item label="使用状态" prop="isUse">
            <el-select
              v-model="sampleImgForm.isUse"
              clearable
              placeholder="请选择使用状态查询"
            >
              <el-option label="开启" :value="true" />
              <el-option label="关闭" :value="false" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round :loading="ampleImgLoading" @click="searchAmpleImg(1)"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <GlobalTable
          ref="GlobalTable"
          v-loading="ampleImgLoading"
          isIndex
          :columns="ampleImgColumns"
          :data="ampleImgDataList"
          :pageSizes="[5,10,20,50]"
          :currentPage="sampleImgForm.pageNum"
          :pageSize="sampleImgForm.pageSize"
          :total="sampleImgForm.total"
          layout="total,sizes, prev, pager, next, jumper"
          @handleCurrentChange="searchAmpleImg"
          @handleSizeChange="handleAmpleImgSizeChange"
        >
          <el-table-column label="示例图" slot="pictureUri" align="center">
            <template slot-scope="{ row }">
              <img :src="row.pictureUri" style="width: 70px;height:70px" alt="" @click="seeSampleImage(row)" />
            </template>
          </el-table-column>
          <el-table-column label="说明" slot="instruction" align="center">
            <template slot-scope="{ row }">
              <template v-if="row.instruction">
                <el-tooltip class="item" effect="dark" :content="row.instruction" placement="top">
                  <div class="nowrap-ellipsis">{{ row.instruction }}</div>
                </el-tooltip>
              </template>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="使用范围" slot="useScope" align="center">
            <template slot-scope="{ row }">
              <el-select
                size="small"
                v-model="row.useScope"
                @change="useScopeChange(row)"
              >
                <el-option label="全部机型" value="00" />
                <el-option label="部分机型" value="01" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="使用机型" slot="setMachineNum" align="center">
            <template slot-scope="{ row }">
              <template v-if="row.useScope === '00'">
                <span>全部机型</span>
              </template>
              <template v-if="row.useScope === '01'">
                <div class="txt-underline" @click="editModel(row,'see')">指定{{ row.setMachineNum }}个机型</div>
                <div class="txt-underline" @click="editModel(row,'add')">添加机型</div>
              </template>
              
            </template>
          </el-table-column>
          <el-table-column label="使用状态" slot="isUse" align="center">
            <template slot-scope="{ row }">
              <el-switch
                inactive-color="#bebebe"
                @change="handleIsUseSwitch(row)"
                v-model="row.isUse"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button type="warning" size="small" @click="editAmpleImg(row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
    </div>
    <el-dialog
      title="回收商列表"
      :visible.sync="addMerchantDiaShow"
      :close-on-click-modal="false"
      width="1200px"
      top="2%"
      @closed="addMerchantDiaClose"
    >
      <div style="display:flex;justify-content: space-between;">
        <div style="width: 630px;margin-right:20px">
          <el-form :inline="true" :model="diaMerchantForm">
            <el-form-item label="回收商" prop="merchantId">
              <el-select
                size="small"
                v-model="diaMerchantForm.merchantId"
                clearable
                filterable
                placeholder="请输入回收商名称查询"
              >
                <el-option
                  v-for="item in merchantList"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="dialogSearchMerchant(1)"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-if="addMerchantDiaShow"
            ref="addMerchantDia"
            :data="dialogTableData"
            v-loading="merchantDiaLoading"
            tooltip-effect="dark"
            border
            max-height="540"
            @selection-change="handSelect"
            :row-key="
              (row) => {
                return row.merchantId;
              }
            "
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              :index="
                (index) => {
                  return (
                    index +
                    1 +
                    (diaMerchantForm.pageNum - 1) * diaMerchantForm.pageSize
                  );
                }
              "
            />
            <el-table-column label="回收商名称" align="center">
              <template slot-scope="{ row }">{{
                row.merchantName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系人" align="center">
              <template slot-scope="{ row }">{{
                row.contactName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{
                row.contactPhone || "--"
              }}</template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right; margin-top: 20px"
            @current-change="dialogSearchMerchant"
            :current-page="diaMerchantForm.pageNum"
            layout="total,  prev, pager, next, jumper"
            :total="diaMerchantForm.total"
            :background="true"
          >
          </el-pagination>
        </div>
        <div style="width: 520px;">
          <p style="margin-bottom:20px">
            已选商户({{ selectedMerchantList.length }})
          </p>
          <el-table
            ref="addtipleTable"
            :data="selectedMerchantList"
            tooltip-effect="dark"
            border
            max-height="640"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column label="回收商名称" align="center">
              <template slot-scope="{ row }">{{
                row.merchantName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系人" align="center">
              <template slot-scope="{ row }">{{
                row.contactName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="联系电话" align="center">
              <template slot-scope="{ row }">{{
                row.contactPhone || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <p
                  @click="detaleCompy(row)"
                  style="color:red;cursor: pointer;text-decoration:underline"
                >
                  删除
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            addMerchantDiaShow = false;
            selectedMerchantList = [];
          "
          >取消</el-button
        >
        <el-button type="primary" @click.native="addMerchantSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="安全验证"
      :visible.sync="commandDisableShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div v-if="tipExplain" style="margin-bottom: 20px;">
        <span style="color: #ff8695;font-size: 14px;">{{ tipExplain }}</span>
      </div>
      <div class="tip-centent">
        <span>{{ commandTipText }}</span>
      </div>
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="commandSureAdd"
          :loading="commandButLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="订单过滤最大时效"
      :visible.sync="ageingDiaShow"
      width="550px"
      :close-on-click-modal="false"
      @closed="command = ''"
    >
      <p class="tip-txt mb-10">
        说明：设置后，商户能过滤的订单时效最高值为该设定值
      </p>
      <div class="flex" style="margin-top: 10px;align-items: flex-start;">
        <span class="label-S" style="min-width: 70px;"><span class="label-must">*</span>时效过滤上限：</span>
        <div>
          <div>
            <span>此商户最多可过滤小于</span>
            <el-input-number
              style="margin: 0 10px;"
              :precision="0"
              size="small"
              v-model="time"
              :min="0"
              :max="99999"
              @blur="time = time || 0"
            ></el-input-number>
            <span>秒的订单</span>
          </div>
          <div style="margin-top: 10px;color: #999;font-size: 14px;">此处需填入区间为10≤报价时效≤60，填入整数</div>
        </div>
      </div>
      <div class="flex" style="margin-top: 10px;align-items: flex-start;">
        <span class="label-S" style="min-width: 70px;flex-shrink: 0;"><span class="label-must">*</span>谷歌验证码：</span>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ageingDiaShow = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="ageingDiaBtnLoading"
          @click="ageingDiaSure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog v-if="imageDialogVisible" :title="imageDialogTitle" :visible.sync="imageDialogVisible" :close-on-click-modal="false" width="600px">
      <el-form ref="imageForm" :model="imageFormData" :rules="imageFormRule" label-width="110px">
        <el-form-item label="示例图名称：" prop="pictureName">
          <el-input v-model.trim="imageFormData.pictureName" maxlength="10" placeholder="请输入示例图名称" type="text" size="small" />
        </el-form-item>
        <el-form-item label="示例图图片：" prop="pictureUri">
          <ElImgUpload
            :isBigImg="true"
            :exist-image="imageFormData.pictureUri"
            placeTxt="提示：图片仅支持上传jpg/png/gif格式，建议尺寸：340-340px"
            @handleChange="sampleHandleChange"/>
        </el-form-item>
        <el-form-item label="示例图说明：">
          <el-input
            size="small"
            type="textarea"
            placeholder="请输入说明"
            v-model="imageFormData.instruction"
            maxlength="50"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="imageDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="imageBtnLoading" @click.native="imageHandleSure">确定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false;
    }
      " :url-list="imgList" />
    <SeeModelDia
      v-if="seeModelDiaShow"
      :id="imgRowId"
      :dialogVisible="seeModelDiaShow"
      :brandNoListData="brandNoListData"
      @close="closeSeeModelDia"
      @setMachineUpdate="setMachineUpdate"
      ></SeeModelDia>
    <ModelSelectDia
      v-if="modelSelectDiaShow"
      :id="imgRowId"
      :brandNoListData="brandNoListData"
      :dialogVisible="modelSelectDiaShow"
      @close="closeModelSelectDia"
      @setMachineUpdate="setMachineUpdate"
    ></ModelSelectDia>
    <el-dialog
      title="抢单顺序设置"
      :visible.sync="examineNextSortVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <p style="color: #ff8695;font-size: 14px;">说明：抢单顺序即为审单员拿取下一单时的顺序，越早加入队列的订单，审单报价时效会越短</p>
      <el-form label-width="100px">
        <el-form-item label="选择顺序" required>
          <el-radio-group v-model="examineNextSort">
            <el-radio label="00">最早加入队列</el-radio>
            <el-radio label="01">最晚加入队列</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="谷歌验证码" required>
          <el-input v-model="command" placeholder="请输入谷歌验证码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="examineNextSortVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="examineNextSortBtnLoading"
          @click.native="examineNextSortSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ElImgUpload from "../../../../components/global/components/elements/upload-element";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import ModelSelectDia from "./dialog/ModelSelectDia.vue";
import SeeModelDia from "./dialog/SeeModelDia.vue";
export default {
  components: { ElImageViewer,ElImgUpload,ModelSelectDia,SeeModelDia },
  data() {
    return {
      merchantLoading: false,
      merchantForm: {
        merchantId: "",
        isOpenExamineQuote: "",
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      merchantList: [],
      brandNoListData: [],
      merchantDataList: [],
      merchantColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
        { slotName: "openExamineQuoteAdmin" },
        { slotName: "openExamineQuoteTime" },
        { slotName: "isEnable" },
        { slotName: "examineFilterMaxSeconds" },
        { slotName: "examineNextSort" },
        { slotName: "isOpenExamineQuoteWatermark" },
        { slotName: "isExamineUnStart" },
        { slotName: "isEnableWechatExamine" },
        { slotName: "isExamineRejectQuote" },
        { slotName: "isOpenExamineQuote" },
      ],
      addMerchantDiaShow: false,
      merchantDiaLoading: false,
      dialogTableData: [],
      selectedMerchantList: [],
      diaMerchantForm: {
        merchantId: "",
        isOpenExamineQuote: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableMerchantColumns: [
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "联系电话", prop: "contactPhone" },
      ],
      // 安全验证
      commandTipText: "",
      tipExplain: "",
      commandType: "",
      commandDisableShow: false,
      command: "",
      commandButLoading: false,
      rowObj: {}, //操作当前行
      // 审单报价过滤最大时效弹框
      ageingDiaShow: false,
      time: "",
      ageingDiaBtnLoading: false,

      // 审单示例图
      sampleImgForm: {
        isUse: "",
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      ampleImgLoading: false,
      ampleImgDataList: [],
      ampleImgColumns: [
        { label: "示例图名称", prop: "pictureName" },
        { slotName: "pictureUri" }, // 示例图
        { slotName: "instruction" }, // 说明
        { slotName: "useScope" }, // 使用范围
        { slotName: "setMachineNum" }, // 使用机型
        { slotName: "isUse" }, // 使用状态
        { slotName: "operation" }, // 操作
      ],
      imgList: [],
      showViewer: false,
      // 示例图新增/编辑弹框
      imageDialogTitle: "",
      imageDialogVisible: false,
      imageFormData: {
        pictureName: "",
        pictureUri: "",
        instruction: ""
      },
      imageFormRule: {
        pictureName: [{ required: true, message: '请输入示例图名称', trigger: 'change' }],
        pictureUri: [{ required: true, message: '请上传图片', trigger: 'change' }],
      },
      imageBtnLoading: false,
      imgRowId: "",
      seeModelDiaShow: false,
      modelSelectDiaShow: false,
      examineNextSortVisible: false,
      examineNextSortBtnLoading: false,
      examineNextSort: "",

    };
  },
  created() {
    this.searchMerchant(1);
    this.getMerchantList();
    this.getoldBrandSelectList()
    this.searchAmpleImg(1)
  },
  methods: {
    // 获取品牌下拉
    getoldBrandSelectList() {
      _api.oldBrandSelectList().then((res) => {
        this.brandNoListData = res.data;
      });
    },
    getMerchantList() {
      _api.getMerchantSelectList().then((res) => {
        this.merchantList = res.data;
      });
    },
    // 查询商家
    searchMerchant(pageNum) {
      this.merchantLoading = true;
      _api
        .examineQuoteList({
          ...this.merchantForm,
          pageNum: pageNum || this.merchantForm.pageNum,
        })
        .then((res) => {
          this.merchantDataList = res.data.records;
          this.merchantForm.pageNum = res.data.current;
          this.merchantForm.total = res.data.total;
        })
        .finally(() => {
          this.merchantLoading = false;
        });
    },
    handleMerchantSizeChange(num) {
      this.merchantForm.pageSize = num
      this.searchMerchant(1)
    },
    // 水印状态切换
    handleSwitch(row) {
      this.commandTipText = row.isOpenExamineQuoteWatermark?'是否确定开启该商家水印？':'是否确定关闭该商家水印？';
      this.commandType = "water";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    handleStartSwitch(row) {
      this.commandTipText = row.isExamineUnStart?'是否确定开启该商家无法开机推单？':'是否确定关闭该商家无法开机推单？';
      this.commandType = "start";
      this.tipExplain = row.isExamineUnStart?"移除后，需在系统管理-菜单管理中隐藏该商家的审单报价菜单":''
      this.commandDisableShow = true;
      this.rowObj = row
    },
    handleXcxSwitch(row) {
      this.commandTipText = row.isEnableWechatExamine?'是否确认开启小程序审单？':'是否确认关闭小程序审单？';
      this.tipExplain = row.isEnableWechatExamine?'开启后，商户可以自行选择审单员使用小程序审单还是后台审单，两种模式只可选择一种':'关闭后，审单员需全部使用后台审单，已登录小程序的审单员将被下线';
      this.commandType = "xcx";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    handleRefuseSwitch(row) {
      this.commandTipText = row.isExamineRejectQuote?'是否确认开启？':'是否确认关闭？';
      this.tipExplain = row.isExamineRejectQuote?'开启后，审单员审单页面拥有“无法报价”按钮':'关闭后，审单员审单页面没有“无法报价”按钮';
      this.commandType = "reject";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 审单状态
    handleStatusSwitch(row) {
      this.commandTipText = row.isOpenExamineQuote?'是否确认开启审单功能？':'是否确认关闭审单功能？';
      this.commandType = "examineStatus";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 移除商家、
    delmerchant(row) {
      this.commandTipText = "是否确定移除该商家？";
      this.commandType = "del";
      this.tipExplain = "移除后，需在系统管理-菜单管理中隐藏该商家的审单报价菜单"
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 打开添加回收商弹框
    openAddMerchantDia() {
      this.dialogSearchMerchant(1);
      this.addMerchantDiaShow = true;
    },
    // 确认添加商家
    addMerchantSubmit() {
      this.commandTipText = "确定添加回收商吗？";
      this.commandType = "add";
      this.commandDisableShow = true;
    },
    // 添加商家弹框查询
    dialogSearchMerchant(pageNum) {
      this.merchantDiaLoading = true;
      _api
        .examineQuoteNonelist({
          ...this.diaMerchantForm,
          pageNum:pageNum || this.diaMerchantForm.pageNum,
        })
        .then((res) => {
          this.dialogTableData = res.data.records;
          this.diaMerchantForm.pageNum = res.data.current;
          this.diaMerchantForm.total = res.data.total;
          this.dialogTableData.forEach((item) => {
            if (!this.selectedMerchantList.some((selectedItem) => selectedItem.merchantId === item.merchantId)) {
                this.$nextTick(()=>{
                    this.$refs.addMerchantDia.toggleRowSelection(item, false);
                })
            }
            console.log(this.selectedMerchantList.length,'this.selectedMerchantList.length');
            if (!this.selectedMerchantList.length) {
              this.$refs.addMerchantDia.clearSelection();
            }
          });
        })
        .finally(() => {
          this.merchantDiaLoading = false;
        });
    },
    // 添加商家弹框关闭
    addMerchantDiaClose() {
      this.diaMerchantForm = {
        merchantId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      this.commandTipText = "";
      this.addMerchantDiaShow = false;
      this.selectedMerchantList = [];
    },
    //删除
    detaleCompy(row) {
      let flag = true;
      this.dialogTableData.forEach(item => {
        if(item.merchantId === row.merchantId){
          flag = false
          this.$refs.addMerchantDia.toggleRowSelection(item, false);
        }
      })
      if (flag) {
        this.selectedMerchantList = this.selectedMerchantList.filter(item => item.merchantId !== row.merchantId);
      }
    },    handSelect(selection, row) {
      console.log(selection, row, "selection, rowselection, rowselection, row");
      this.selectedMerchantList = selection;
    },
    // 安全验证添加商家
    commandSureAdd() {
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码!");
      }
      this.commandButLoading = true;
      if (this.commandType == "add") {
        let merchantIds = [];
        this.selectedMerchantList.forEach((item) => {
          merchantIds.push(item.merchantId);
        });
        let parame = {
          command: this.command,
          merchantIds,
        };
        _api
          .examineQuoteSaveBatch(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("添加成功!");
              this.commandDisableShow = false;
              this.addMerchantDiaShow = false;
              this.merchantForm.pageNum = 1
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "del") {
        let parame = {
          command: this.command,
          merchantId: this.rowObj.merchantId,
        };
        _api
          .examineQuoteRemove(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("移除成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "water") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isOpenExamineQuoteWatermark,
        };
        _api
          .updateIsOpenWatermark(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if (this.commandType == "start") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isExamineUnStart,
        };
        _api
          .updateIsExamineUnStart(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      } else if(this.commandType == "xcx") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isEnableWechatExamine,
        };
        _api
          .updateIsEnableWechatExamine(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
        }else if(this.commandType == "examineStatus") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isOpenExamineQuote,
        };
        _api
          .updateIsOpenExamineQuote(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }else if(this.commandType == "imgStatus") {
        let parame = {
          command: this.command,
          samplePictureId: this.rowObj.id,
          isUse: this.rowObj.isUse,
        };
        _api
          .updateSamplePictureState(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }else if(this.commandType == "reject") {
        let parame = {
          command: this.command,
          id: this.rowObj.merchantId,
          isOpen: this.rowObj.isExamineRejectQuote,
        };
        _api
          .updateIsExamineRejectQuote(parame)
          .then((res) => {
            if (res.code === 1) {
              this.$message.success("操作成功!");
              this.commandDisableShow = false;
            }
          })
          .finally(() => {
            this.commandButLoading = false;
          });
      }
    },
    // 安全提示弹框关闭回调
    commandClosed() {
      this.command = ''
      this.tipExplain = ''
      if(this.commandType === 'imgStatus'){
        this.searchAmpleImg();
      }else{
        this.searchMerchant();
      }
    },
    examineFilterMaxSecondsSet(row) {
      this.time = row.examineFilterMaxSeconds
      this.rowObj = row
      this.ageingDiaShow = true
    },
    ageingDiaSure() {
      if(this.time < 10){
        this.$message.error('时效过滤上限不可小于10秒')
        return
      }
      if (!this.command) {
        return this.$message.error("请输入谷歌动态码!");
      }
      let parame = {
        merchantId: this.rowObj.merchantId,
        examineFilterMaxSeconds: this.time,
        command: this.command
      }
      this.ageingDiaBtnLoading = true
      this.$confirm(`是否确认此商户最多可过滤小于${this.time}秒的订单`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _api.setOrderFilterMaxSeconds(parame).then(res => {
          if(res.code === 1){
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.ageingDiaShow = false
            this.searchMerchant()
          }
        }).finally(() => {
          this.ageingDiaBtnLoading = false;
        });
      }).catch(() => {
        this.ageingDiaBtnLoading = false;         
      });
      
    },

    // 审单示例图
    searchAmpleImg(pageNum) {
      this.ampleImgLoading = true;
      _api
        .samplePictureList({
          ...this.sampleImgForm,
          pageNum: pageNum || this.sampleImgForm.pageNum,
        })
        .then((res) => {
          this.ampleImgDataList = res.data.records;
          this.sampleImgForm.pageNum = res.data.current;
          this.sampleImgForm.total = res.data.total;
        })
        .finally(() => {
          this.ampleImgLoading = false;
        });
    },
    handleAmpleImgSizeChange(num) {
      this.sampleImgForm.pageSize = num
      this.searchAmpleImg(1)
    },
    handleIsUseSwitch(row) {
      this.commandTipText = row.isUse?'是否确认开启示例图':'是否确认关闭示例图';
      this.tipExplain = row.isUse?'开启后，此示例图将在审单时展示':'关闭后，此示例图将不在审单时展示';
      this.commandType = "imgStatus";
      this.commandDisableShow = true;
      this.rowObj = row
    },
    // 添加示例图弹框显示
    openAddSampleImgDia() {
      this.imageDialogTitle = "添加示例图"
      this.imageFormData = {
        pictureName: "",
        pictureUri: "",
        instruction: ""
      }
      this.imageDialogVisible = true
    },
    // 编辑示例图
    editAmpleImg(row) {
      this.imageFormData.pictureName = row.pictureName
      this.imageFormData.pictureUri = row.pictureUri
      this.imageFormData.instruction = row.instruction
      this.imageFormData.samplePictureId = row.id
      this.imageDialogTitle = "编辑示例图"
      this.imageDialogVisible = true
    },
    // 查看示例图大图
    seeSampleImage(row) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(row.pictureUri);
    },
    // 圖片更換
    sampleHandleChange(e) {
      this.imageFormData.pictureUri = e;
    },
    // 示例图保存确定
    imageHandleSure() {
      this.$refs['imageForm'].validate((valid) => {
        if (valid) {
          this.imageBtnLoading = true;
          if(this.imageDialogTitle == '添加示例图'){
            _api.addSamplePicture(this.imageFormData).then((res) => {
              if (res.code === 1) {
                this.$message.success(res.msg || "操作成功");
                this.imageDialogVisible = false;
              }
              this.imageBtnLoading = false;
              this.searchAmpleImg(1)
            })
            .catch(() => {
              this.imageBtnLoading = false;
            });
          }else{
            _api.updateSamplePicture(this.imageFormData).then((res) => {
              if (res.code === 1) {
                this.$message.success(res.msg || "操作成功");
                this.imageDialogVisible = false;
                this.searchAmpleImg()
              }
              this.imageBtnLoading = false;
            })
            .catch(() => {
              this.imageBtnLoading = false;
            });
          }
          
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editModel(row, type) {
      this.imgRowId = row.id;
      if (type === "see") {
        this.seeModelDiaShow = true;
      }
      if (type === "add") {
        this.modelSelectDiaShow = true;
      }
    },
    // 关闭机型查看弹框
    closeSeeModelDia() {
      this.seeModelDiaShow = false;
    },
    // 关闭机型查看弹框
    closeModelSelectDia() {
      this.modelSelectDiaShow = false;
      this.searchAmpleImg()
    },
    useScopeChange(row) {
      console.log(row,'row');
      let params = {
        samplePictureId: row.id,
        useScope: row.useScope
      }
      _api.updateSamplePictureScope(params).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功');
        }
      })
      if(row.useScope === '01' && row.setMachineNum<=0){
        this.imgRowId = row.id
        this.modelSelectDiaShow = true;
      }
    },
    setMachineUpdate(val) {
      if(val){
        this.searchAmpleImg()
      }
    },
    openExamineNextSortDia(row) {
      this.rowObj = JSON.parse(JSON.stringify(row))
      this.command = ""
      this.examineNextSort = row.examineNextSort
      this.examineNextSortVisible = true
    },
    examineNextSortSure() {
      if (!this.command.trim()) {
        return this.$message.error("请输入谷歌验证码!");
      }
      this.examineNextSortBtnLoading = true
      let params = {
        command: this.command,
        examineNextSort: this.examineNextSort,
        merchantId: this.rowObj.merchantId
      }
      _api.changeExamineNextSort(params).then(res => {
        if(res.code === 1){
          this.$message.success("操作成功!")
          this.examineNextSortVisible = false
          this.searchMerchant();
        }
      }).finally(() => {
        this.examineNextSortBtnLoading = false;
      });
    }
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.review-settings {
  .txt-underline{
    color: #0981FF;
    text-decoration: underline;
    cursor: pointer;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-je {
    display: flex;
    justify-content: flex-end;
  }
  .merchant-list {
    padding: 20px;
    background: white;
    border-radius: 20px;
    .title {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }
  }
  .addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }
  .tip-centent {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  .command {
    display: flex;
    align-items: center;
    /deep/.el-input {
      width: 300px;
    }
  }
  .tip-txt {
    color: #ff687b;
    font-size: 14px;
  }
  .label-S {
    min-width: 100px;
    text-align: right;
    margin-right: 20px;
    line-height: 32px;
    .label-must {
      color: #ff687b;
      margin-right: 4px;
    }
  }
  .nowrap-ellipsis{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
</style>
