<template>
  <div class="see-model-dia">
    <el-dialog
      title="查看机型"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="700px"
      top="3%"
      @close="close"
    >
      <el-form :inline="true" :model="diaMachineForm">
        <el-form-item label="品牌">
          <el-select
            size="small"
            v-model="diaMachineForm.brandId"
            placeholder="请选择品牌名查询"
            filterable
            clearable
          >
            <el-option
              v-for="item in brandNoListData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机型">
          <el-input
            placeholder="请输入机型名称"
            size="small"
            v-model="diaMachineForm.machineName"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="dialogSearchMachine()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div class="flex-je mb-10">
        <div>
          <el-button size="small" :disabled="!selectedMachineList.length" type="danger" @click="batchRemoveMachine"
            >批量移除</el-button
          >
          <el-button size="small" type="primary" @click="newAddMachine"
            >新增机型</el-button
          >
        </div>
      </div>
      <el-table
        ref="machineDia"
        :data="dialogTableData"
        v-loading="diaLoading"
        tooltip-effect="dark"
        border
        @selection-change="handSelect"
        :row-key="
          (row) => {
            return row.machineId;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          :index="
            (index) => {
              return (
                index +
                1 +
                (diaMachineForm.pageNum - 1) * diaMachineForm.pageSize
              );
            }
          "
        />
        <el-table-column label="品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型" align="center">
          <template slot-scope="{ row }">{{
            row.machineName || "--"
          }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="danger" size="small" @click="delMachine(row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right; margin-top: 20px"
        @current-change="dialogSearchMachine"
        :current-page="diaMachineForm.pageNum"
        layout="total,  prev, pager, next, jumper"
        :total="diaMachineForm.total"
        :background="true"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="close">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="removeTitle" :visible.sync="dialogSureVisible" :close-on-click-modal="false"
      width="550px" @closed="rowMachineId=''">
      <div style="font-size: 18px;color: #666666;text-align: center;height: 100px;line-height: 100px;">
        是否确认{{removeTitle=='批量移除提示'?'批量':''}}移除？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button type="primary" :loading="sureHandleBtnLoading" @click.native="sureHandleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <ModelSelectDia v-if="modelSelectDiaShow" :id="id" :brandNoListData="brandNoListData" :dialogVisible="modelSelectDiaShow" @close="closeModelSelectDia" @setMachineUpdate="setMachineUpdate"></ModelSelectDia>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ModelSelectDia from "./ModelSelectDia.vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: [String, Number],
    brandNoListData: {
      type: Array,
      default: ()=>[],
    },
  },
  components: { ModelSelectDia },
  data() {
    return {
      diaMachineForm: {
        brandId: "",
        pageNum: 1,
        pageSize: 10,
      },
      diaLoading: false,
      dialogTableData: [],
      selectedMachineList: [], 
      dialogSureVisible: false,
      rowMachineId: '',
      sureHandleBtnLoading: false,
      modelSelectDiaShow: false,
      removeTitle: "",
    };
  },
  
  created() {
    this.dialogSearchMachine();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    dialogSearchMachine(pageNum) {
      this.diaLoading = true;
      _api
        .findSamplePictureMachine({
          ...this.diaMachineForm,
          samplePictureId: this.id,
          pageNum: pageNum || 1,
        })
        .then((res) => {
          this.dialogTableData = res.data.records;
          this.diaMachineForm.pageNum = res.data.current;
          this.diaMachineForm.total = res.data.total;
          if(this.dialogTableData.length === 0 && this.diaMachineForm.pageNum > 1){
            this.dialogSearchMachine(this.merchantForm.pageNum-1)
          }
        })
        .finally(() => {
          this.diaLoading = false;
        });
    },
    //多选操作
    handSelect(selection, row) {
      console.log(selection, row, "selection, rowselection, rowselection, row");
      this.selectedMachineList = selection;
    },
    // 移除操作
    delMachine(row) {
        this.removeTitle = "移除提示"
        this.rowMachineId = row.machineId
        this.dialogSureVisible = true
    },
    // 确认弹框确认
    sureHandleSuccess() {
      let params = {
        samplePictureId: this.id,
        machineIds: this.rowMachineId?[this.rowMachineId]:this.selectedMachineList.map(item => item.machineId)
      }
      this.sureHandleBtnLoading = true
      _api.removeSamplePictureMachine(params).then(res => {
          if(res.code === 1){
              this.$message.success(res.msg);
              this.dialogSearchMachine(this.diaMachineForm.pageNum)
              this.$emit("setMachineUpdate",true)
          }
          this.dialogSureVisible = false
      }).finally(() => {
        this.sureHandleBtnLoading = false;
      });
    },
    //批量移除机型
    batchRemoveMachine(){
        this.removeTitle = "批量移除提示"
        this.dialogSureVisible = true
    },
    //新增机型
    newAddMachine(){
      this.modelSelectDiaShow = true
    },
    // 关闭机型添加弹框
    closeModelSelectDia() {
      this.modelSelectDiaShow = false
    },
    setMachineUpdate(val) {
      if(val){
        this.closeModelSelectDia()
        this.dialogSearchMachine()
        this.$refs.machineDia.clearSelection();
      }
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.see-model-dia{
    .flex-jb{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .flex-je{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mb-10{
        margin-bottom: 10px;
    }
    /deep/.el-form-item{
        margin-bottom: 10px !important;
    }
    .red-tip {
      color: #ff687b;
      font-size: 14px;
      line-height: 14px;
    }
}
</style>
