<template>
  <div class="model-select-dia">
    <el-dialog
      title="添加机型"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="1200px"
      top="2%"
      @close="close"
    >
      <div style="display:flex;justify-content: space-between;">
        <div style="width: 630px;margin-right:20px">
          <el-form :inline="true" :model="diaMachineForm">
            <el-form-item label="品牌">
              <el-select
                size="small"
                v-model="diaMachineForm.brandId"
                placeholder="请选择品牌名查询"
                filterable
                clearable
              >
                <el-option
                  v-for="item in brandNoListData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="机型">
              <el-input
                placeholder="请输机型名称"
                size="small"
                v-model="diaMachineForm.machineName"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                type="primary"
                @click="dialogSearchMachine()"
                >查询</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-if="dialogVisible"
            ref="addMachineDia"
            :data="dialogTableData"
            v-loading="leftDiaLoading"
            tooltip-effect="dark"
            border
            max-height="550"
            @selection-change="handSelect"
            :row-key="
              (row) => {
                return row.machineId;
              }
            "
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              align="center"
            >
            </el-table-column>
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
              :index="
                (index) => {
                  return (
                    index +
                    1 +
                    (diaMachineForm.pageNum - 1) * diaMachineForm.pageSize
                  );
                }
              "
            />
            <el-table-column label="品牌" align="center">
              <template slot-scope="{ row }">{{
                row.brandName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="机型名称" align="center">
              <template slot-scope="{ row }">{{
                row.machineName || "--"
              }}</template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="text-align: right; margin-top: 20px"
            @current-change="dialogSearchMachine"
            :current-page="diaMachineForm.pageNum"
            layout="total,  prev, pager, next, jumper"
            :total="diaMachineForm.total"
            :background="true"
          >
          </el-pagination>
        </div>
        <div style="width: 520px;">
          <p style="margin-bottom:20px">
            已选机型({{ selectedMachineList.length }})
          </p>
          <el-table
            ref="addtipleTable"
            :data="selectedMachineList"
            tooltip-effect="dark"
            border
            max-height="640"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              align="center"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column label="品牌" align="center">
              <template slot-scope="{ row }">{{
                row.brandName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="机型名称" align="center">
              <template slot-scope="{ row }">{{
                row.machineName || "--"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <p
                  @click="detaleSelectMachine(row)"
                  style="color:red;cursor: pointer;text-decoration:underline"
                >
                  删除
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="close"
          >取消</el-button
        >
        <el-button type="primary" @click.native="addMachineSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="添加提示"
      :visible.sync="dialogSureVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <div
        style="font-size: 18px;color: #666666;text-align: center;height: 100px;line-height: 100px;"
      >
        是否确认添加机型？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button type="primary" :loading="sureHandleBtnLoading" @click.native="sureHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: [String, Number],
    brandNoListData: {
      type: Array,
      default: ()=>[],
    },
  },
  data() {
    return {
      diaMachineForm: {
        brandId: "",
        machineName: "",
        pageNum: 1,
        pageSize: 10,
      },
      expenseDeductionList: [], //扣费项列表
      dialogTableData: [],
      leftDiaLoading: false, //左侧表格loading
      selectedMachineList: [],
      dialogSureVisible: false,
      sureHandleBtnLoading: false,
    };
  },
  created() {
    this.dialogSearchMachine()
  },
  methods: {
    // 用来显示扣费项情况
    findLabel(arr) {
      if (!arr || !arr.length) return '--'
      let str = arr.map(({classifyOptionName,optionPrice}) => `${classifyOptionName}-${optionPrice}`).join('、')
      return str
    },
    close() {
      this.selectedMachineList = [];
      this.$emit("close");
    },
    dialogSearchMachine(pageNum) {
      this.leftDiaLoading = true;
      _api
        .findNoSamplePictureMachine({
          ...this.diaMachineForm,
          samplePictureId: this.id,
          pageNum: pageNum || 1,
        })
        .then((res) => {
          this.dialogTableData = res.data.records;
          this.diaMachineForm.pageNum = res.data.current;
          this.diaMachineForm.total = res.data.total;
          this.dialogTableData.forEach((item) => {
            if (!this.selectedMachineList.some((selectedItem) => selectedItem.machineId === item.machineId)) {
                this.$nextTick(()=>{
                    this.$refs.addMachineDia.toggleRowSelection(item, false);
                })
            }
            if (!this.selectedMachineList.length) {
              this.$refs.addMachineDia.clearSelection();
            }
          });
        })
        .finally(() => {
          this.leftDiaLoading = false;
        });
    },
    handSelect(selection, row) {
      console.log(selection, row, "selection, rowselection, rowselection, row");
      this.selectedMachineList = selection;
    },
    //删除
    detaleSelectMachine(row) {
      let flag = true;
      this.dialogTableData.forEach(item => {
        if(item.machineId === row.machineId){
          flag = false
          this.$refs.addMachineDia.toggleRowSelection(item, false);
        }
      })
      if (flag) {
        this.selectedMachineList = this.selectedMachineList.filter(item => item.machineId !== row.machineId);
      }
    },
    // 添加确认弹框
    addMachineSubmit() {
      if(!this.selectedMachineList.length){
        this.$message.error('请先选择机型');
        return
      }
      this.dialogSureVisible = true
    },
    // 确认弹框确认
    sureHandleSuccess() {
      let machineIds = []
      this.selectedMachineList.forEach(item => {
        machineIds.push(item.machineId)
      })
      console.log(machineIds,'machineIdsmachineIdsmachineIds');
      
      let params = {
        samplePictureId: this.id,
        machineIds,
      }
      this.sureHandleBtnLoading = true
      _api.addSamplePictureMachine(params).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg);
          
          this.$emit("setMachineUpdate",true)
        }
        this.dialogSureVisible = false
        this.close()
      }).finally(() => {
          this.sureHandleBtnLoading = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
  .nowrap-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
</style>
